<template>
  <div>
    <Loading :active="isLoading" />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { GET_JWT } from "@/store/auth.module"
import { POST_SUBSCRIPTION } from "@/store/subscription.module"
import Swal from "sweetalert2";
import config from "@/ConfigProvider";

export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch(GET_JWT);
      setTimeout(async () => {
        await this.$store.dispatch(POST_SUBSCRIPTION, {
          package_slug: this.$route.params.package,
          voucher_code: this.$route.params.voucher ?? "",
        }).then((data) => {
          if (this.$route.params.package == "trial") {
            // this.$router.push({ name: "Dashboard Web CV" });
            window.location.href = config.value("FE_URL")+"/employee/profile";
          } else {
            window.location.href = data.redirect_url;
          }
        }).catch(err => {
          this.isLoading = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            onClose: () => {
              // this.$router.push({ name: "Dashboard Web CV" });
              window.location.href = config.value("FE_URL")+"/employee/profile";
            }
          });
        })
      }, 100);
    }
  }
}
</script>
